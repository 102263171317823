import React, { useEffect, useState } from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import { HydrationProvider } from 'react-hydration-provider';

import {
  INTITAL_STRAPI_CONTEXT,
  StrapiStateContext,
} from '../constants/strapi-context';

function StrapiConfigWrapper({ children }: PageProps) {
  const [strapiState, setStrapiState] = useState(INTITAL_STRAPI_CONTEXT);

  const { strapiFreedomIndex, strapiFreedomIndexEs } = useStaticQuery(graphql`
    query {
      strapiFreedomIndexEs: strapiFreedomIndex(locale: { eq: "es" }) {
        assetsUrl
        donationStreamDonationUrl
        donationStreamWorkerUrl
        leadCollectionUrl
      }
      strapiFreedomIndex {
        assetsUrl
        donationStreamDonationUrl
        donationStreamWorkerUrl
        leadCollectionUrl
      }
    }
  `);

  useEffect(() => {
    setStrapiState((state) => ({
      ...state,
      urls: {
        en: strapiFreedomIndex,
        es: strapiFreedomIndexEs,
      },
    }));
  }, [strapiFreedomIndex, strapiFreedomIndexEs]);

  return (
    <HydrationProvider>
      <StrapiStateContext.Provider value={strapiState}>
        {children}
      </StrapiStateContext.Provider>
    </HydrationProvider>
  );
}

export default StrapiConfigWrapper;
