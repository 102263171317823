import { createContext } from 'react';

export const INTITAL_STRAPI_CONTEXT = {
  urls: {
    en: {
      donationStreamWorkerUrl: '',
      leadCollectionUrl: '',
      donationStreamDonationUrl: '',
      assetsUrl: '',
    },
    es: {
      donationStreamWorkerUrl: '',
      leadCollectionUrl: '',
      assetsUrl: '',
    },
  },
  env: {},
};

export const StrapiStateContext = createContext(INTITAL_STRAPI_CONTEXT);
