/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import React from 'react';

// You can delete this file if you're not using it
import './src/styles/fonts.css';
import './src/styles/global.css';
import './src/styles/form-fields.css';
import './src/styles/blocks.css';

import StrapiConfigWrapper from './src/components/strapi-env-wrapper';

export const wrapPageElement = ({ element, props }) => (
  <StrapiConfigWrapper {...props}>{element}</StrapiConfigWrapper>
);
